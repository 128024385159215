$(document).ready(function () {
  function initDropdowns() {
    var $blocks = $(".js-dropdown-group");
    if ($blocks.length === 0) return;

    function setupDropdownGroup($block) {
      var $dropdowns = $block.find(".js-dropdown");
      var $externalTogglers = $(".js-dropdown-toggler-ext");

      function scrollToTop(element) {
        if (element) {
          let elementPosition = element.getBoundingClientRect().top - 100;

          window.scrollTo({
            top: elementPosition + window.scrollY,
            behavior: "smooth",
          });
        }
      }

      function toggleDropdown(event, index) {
        var $dropdown = $dropdowns.eq(index);
        var $content = $dropdown.find(".js-content");
        var keyframes;

        if ($dropdown.hasClass("in-transition")) {
          return;
        }

        // close active dropdown
        $dropdowns.each(function () {
          var $otherDropdown = $(this);
          if ($otherDropdown[0] !== $dropdown[0]) {
            if ($otherDropdown.hasClass("is-open")) {
              var $otherContent = $otherDropdown.find(".js-content");
              $otherDropdown.addClass("in-transition").removeClass("is-open");
              keyframes = [
                { height: $otherContent[0].scrollHeight + "px" },
                { height: 0 },
              ];
              $otherContent.animate(
                { height: keyframes[1].height },
                300,
                function () {
                  $otherContent.removeClass("is-open");
                  $otherDropdown.removeClass("in-transition");
                }
              );
            }
          }
        });

        $dropdown.addClass("in-transition").toggleClass("is-open");

        if ($content.hasClass("is-open")) {
          keyframes = [
            { height: $content[0].scrollHeight + "px" },
            { height: 0 },
          ];
        } else {
          keyframes = [
            { height: 0 },
            { height: $content[0].scrollHeight + "px" },
          ];
        }

        $content.animate({ height: keyframes[1].height }, 300, function () {
          $content.toggleClass("is-open");
          $dropdown.removeClass("in-transition");
        });
      }

      $dropdowns.each(function (index) {
        var $dropdown = $(this);
        var $togglers = $dropdown.find(".js-dropdown-toggler");

        $togglers.on("click", function (event) {
          toggleDropdown(event, index);
          setTimeout(function () {
            scrollToTop(event.currentTarget);
          }, 300);
        });

        $externalTogglers.eq(index).on("click", function (event) {
          toggleDropdown(event, index);
          setTimeout(function () {
            scrollToTop($dropdown[0]);
          }, 300);
        });
      });
    }

    $blocks.each(function () {
      setupDropdownGroup($(this));
    });
  }

  initDropdowns();
});
