jQuery(document).ready(function ($) {
  var $shareButtons = $(".js-share-button");
  var $shareCount = $("#share-count");
  var postId = $shareCount.data("postid");

  if ($shareButtons.length === 0 || !$shareCount.length) {
    return;
  }

  $shareButtons.on("click", function (e) {
    $.ajax({
      url: ANAGLOBALS.ajaxurl,
      type: "post",
      data: {
        action: "update_share_count",
        post_id: postId,
        nonce: ANAGLOBALS.shareNonce,
      },
      success: function (response) {
        if (response.success) {
          $shareCount.text(response.data);
        } else {
          console.log("Error updating share count: ", response.data);
        }
      },
      error: function () {
        console.log("Failed to update share count.");
      },
    });
  });
});
