// SMOOTH SCROLL TO ANCHOR
$(document).on("click", 'a[href^="#"]', function (event) {
  var target = $.attr(this, "href");

  event.preventDefault();

  if (target === "" || target === "#" || target === "#loadMore") {
    return;
  }

  $("html, body").animate(
    {
      scrollTop: $(target).offset().top - 62,
    },
    800
  );
});
