jQuery(document).ready(function ($) {
  function filterEvents(showUpcoming) {
    var currentDate = new Date();
    var hasVisibleEvents = false;

    $(".events__event").each(function () {
      var eventDateElement = $(this).find("[data-date]");
      var eventTimeElement = $(this).find("[data-time]");

      var eventDate = String(eventDateElement.data("date"));
      var eventTime = String(eventTimeElement.data("time"));

      if (!eventDate || eventDate === "undefined") {
        $(this).hide();
        return;
      }

      if (!eventTime || eventTime === "undefined") {
        eventTime = "00:00";
      }

      var eventDateTime = new Date(
        eventDate.substring(0, 4),
        eventDate.substring(4, 6) - 1,
        eventDate.substring(6, 8),
        eventTime.substring(0, 2),
        eventTime.substring(3, 5)
      );

      if (showUpcoming) {
        if (eventDateTime >= currentDate) {
          $(this).show();
          hasVisibleEvents = true;
        } else {
          $(this).hide();
        }
      } else {
        if (eventDateTime < currentDate) {
          $(this).show();
          hasVisibleEvents = true;
        } else {
          $(this).hide();
        }
      }
    });

    if (hasVisibleEvents) {
      $(".js-no-events-message").hide();
    } else {
      $(".js-no-events-message").show();
    }
  }

  $(".js-category-current").on("click", function () {
    filterEvents(true);
    $("#loadMore").hide();
    $(this).addClass("events__category--active");
    $(".js-category-past").removeClass("events__category--active");
  });

  $(".js-category-past").on("click", function () {
    filterEvents(false);
    $("#loadMore").hide();
    $(this).addClass("events__category--active");
    $(".js-category-current").removeClass("events__category--active");
  });
});
