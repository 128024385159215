$(document).ready(function () {
  $(".events__right .events__event").hide();
  $(".events__right .events__event").slice(0, 8).show();
  if ($(".events__right .events__event:hidden").length != 0) {
    $("#loadMore").show();
  } else {
    $("#loadMore").hide();
  }

  $("#loadMore").on("click", function (e) {
    e.preventDefault();
    $(".events__right .events__event:hidden").slice(0, 2).slideDown();
    if ($(".events__right .events__event:hidden").length == 0) {
      $("#loadMore").fadeOut(10);
    }
  });
});
