$(document).ready(function () {
  function initVideos() {
    var videos = $(".js-video-block");
    if (!videos.length) return;

    function setupVideo(videoWrapper) {
      var video = videoWrapper.find(".js-video")[0];
      var source = videoWrapper.find(".js-video source")[0];
      var openers = videoWrapper.find(
        ".js-video-popup-opener, .js-yt-video-popup-opener"
      );

      var lightbox = $(".js-popup");
      var closer = lightbox.find(".js-popup-closer");
      var lightboxVideo = lightbox.find(".js-popup-content")[0];
      var lightboxSource = lightbox.find(".js-popup-content source")[0];
      var lightboxIframeWrapper = lightbox.find(".js-popup-iframe");

      function loadVideo() {
        if (video) {
          if (!source || !source.dataset.src) return;
          if (
            typeof source.src !== "undefined" &&
            source.src == source.dataset.src
          )
            return;
          source.src = source.dataset.src;
          video.load();
        }
      }

      function setupLightboxVideo() {
        var poster = video.getAttribute("poster");
        var type = source.getAttribute("type");
        var src = source.dataset.src;

        lightboxVideo.setAttribute("poster", poster);
        lightboxSource.setAttribute("type", type);
        lightboxSource.src = src;

        lightboxVideo.load();
        lightboxVideo.play();
      }

      function setupYouTubeVideo(videoId) {
        var params = $.param({
          autoplay: 1,
          modestbranding: 1,
          controls: 1,
          rel: 0,
          autohide: 1,
        });
        var iframeSrc =
          "https://www.youtube-nocookie.com/embed/" + videoId + "?" + params;
        lightboxIframeWrapper.html(
          '<iframe class="video-block__yt-iframe" width="560" height="315" src="' +
            iframeSrc +
            '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
        );
      }

      function toggleVideoElements(isYouTube) {
        if (isYouTube) {
          $(lightboxVideo).hide();
          lightboxIframeWrapper.show();
        } else {
          $(lightboxVideo).show();
          lightboxIframeWrapper.hide();
        }
      }

      loadVideo();

      $(window).on("resize", loadVideo);

      openers.each(function () {
        $(this).on("click", function () {
          lightbox.addClass("is-active");
          if (video) {
            video.pause();
          }
          if ($(this).hasClass("js-yt-video-popup-opener")) {
            var videoId = $(this).data("video");
            toggleVideoElements(true);
            setupYouTubeVideo(videoId);
          } else {
            toggleVideoElements(false);
            setupLightboxVideo();
          }
          document.body.style.overflow = "hidden";
        });
      });

      function closeLightbox() {
        lightbox.removeClass("is-active");
        lightboxIframeWrapper.empty();
        if (video) {
          video.play();
        }
        document.body.style.overflow = "unset";
      }

      closer.on("click", closeLightbox);

      lightbox.on("click", function (e) {
        if (
          !$(lightboxVideo).is(e.target) &&
          !$(e.target).hasClass("js-lightbox-video")
        ) {
          closeLightbox();
        }
      });
    }

    videos.each(function () {
      setupVideo($(this));
    });
  }

  initVideos();
});
