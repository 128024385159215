$(".menu.menu--primary .menu__item:nth-child(3)").click(function() {
	$("header").addClass("header--calendar");
	$(this).addClass("menu__item--active-catalog");
});

$("#calendar-close").click(function() {
	$("header").removeClass("header--calendar");
	$(".menu.menu--primary .menu__item:nth-child(3)").removeClass("menu__item--active-catalog");
	$("body").removeClass("fixed");
});
/*
$( document ).ready(function() {
    var inter = setInterval(function(){
        var headerWidth = $('header').innerWidth();
        if ( headerWidth <= 1200 ){
            $('.menu .menu__item:nth-child(3) a').click(function(){
			    $(".menu").removeClass("active");
			    $(".hamburger").removeClass("is-active");
			    $(".calendar").addClass("calendar--responsive");
			});
        } else {
            $('.menu .menu__item:nth-child(3) a').click(function(){
			    $(".calendar").removeClass("calendar--responsive");
			});
        }
    },10);
});*/
