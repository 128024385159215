 $(document).ready(function () {

    if (Boolean(readCookie('hide'))) {
        $('.fast-news').addClass("fast-news--hidden");
        $('body').addClass("fast-news--hidden");
    }
    $('.fast-news #close').click(function (e) {

        $('.fast-news').addClass("fast-news--hidden");
        $('body').addClass("fast-news--hidden");
        e.stopPropagation();

        createCookie('hide', true, 1)
        return false;
    });

    function createCookie(name, value, days) {
        if (days == 1) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        else var expires2 = "";
        document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";

      //  $.cookie(name, value, { expires: days });
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name, "", -1);
    }

});

// ELEMENTS BASED ON HEADER HEIGHT AND FAST NEWS PANEL
$( document ).ready(function() {
    var inter = setInterval(function(){
        var headerHeight = $('header').innerHeight();
        $('body').css('padding-top', headerHeight);
        $('.sticky').css('top', headerHeight);

        var sliderHeight = "calc(100vh - "+headerHeight+"px)";
        $('.hero-slider__item').css('height', sliderHeight);

        $('.menu').css('top', headerHeight);
        if ($('.menu').hasClass('active')){
            $('.menu').css('height', sliderHeight);
        } else {
             $('header .menu').css('height', '0');
        }

        var footerHeight = $('footer').innerHeight();
        var ErrorContainer = footerHeight + headerHeight;
        var errorContainer = "calc(100vh - "+ErrorContainer+"px)";
        $('.error .container').css('height', errorContainer);

        /*var serviceContentHeight = $('.services-item__content').innerHeight();
        var serviceHeaderHeight = serviceContentHeight + headerHeight;
        var ServiceHeaderHeight = "calc(100vh - "+serviceHeaderHeight+"px)";
        $('.services-item__header').css('height', ServiceHeaderHeight);*/

        var calendar = 7 + headerHeight
        $('.calendar').css('top', calendar);

    },10);
});
