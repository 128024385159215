jQuery(document).ready(function ($) {
  $("#native-share")
    .off("click")
    .on("click", function (event) {
      event.preventDefault();

      var title = $(this).data("title");
      var url = $(this).data("url");

      if (navigator.share) {
        navigator
          .share({
            title: title,
            url: url,
          })
          .then(function () {
            console.log("Úspěšně sdíleno");
          })
          .catch(function (error) {
            console.log("Chyba při sdílení:", error);
          });
      } else {
        alert("Nativní sdílení není na tomto zařízení podporováno.");
      }
    });
});
