$(document).ready(function(){
	$('.services__item').hover(function() {
		$( this ).addClass( "services__item--hover" );
		$('.services__item').not(this).removeClass("services__item--hover");
	});
});

$( document ).ready(function() {
    var inter = setInterval(function(){
        var headerWidth = $('header').innerWidth();
        if ( headerWidth <= 1400 ){
			$(".services__item").addClass( "services__item--hover" );
        }
    },10);
});