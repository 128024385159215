$(document).ready(function () {
  $(".js-episodes .podcasts-episode").hide();
  $(".js-episodes .podcasts-episode").slice(0, 8).show();
  if ($(".js-episodes .podcasts-episode:hidden").length != 0) {
    $("#loadMorePodcasts").show();
  } else {
    $("#loadMorePodcasts").hide();
  }

  $("#loadMorePodcasts").on("click", function (e) {
    e.preventDefault();
    $(".js-episodes .podcasts-episode:hidden").slice(0, 4).slideDown();
    if ($(".js-episodes .podcasts-episode:hidden").length == 0) {
      $("#loadMorePodcasts").fadeOut(300);
    }
  });
});
