$(function() {
  $(".gfield input[type='text'], .gfield input[type='tel'], .gfield input[type='email'], .gfield textarea")
    .on("focus", function() {
      $(this)
        .parents(".gfield")
        .addClass("focused");
    })
    .on("blur", function() {
      if ($(this).val().length < 1)
        $(this)
          .parents(".gfield")
          .removeClass("focused");
    });
  $(".gfield textarea").removeAttr("rows");
  $(".gfield textarea").removeAttr("cols");
});

$("input[type='text'], input[type='tel'], input[type='email'], textarea").each(function() {
  var $input = $(this);

  if ($input.val()) {
      $(this).parent().parent(".gfield").addClass("focused");
  }
});

// GFORM #2
var pracovni_pozice = $("body.single-carrier .sticky h2").text();
$("#gform_2 #input_2_1").val(pracovni_pozice);

// TEXTAREA
$(function() {
   $('textarea').autogrow();
});