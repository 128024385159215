jQuery(document).ready(function ($) {
  var $likeButton = $("#like-button");

  if ($likeButton.length === 0) {
    return;
  }

  var postId = $likeButton.data("postid");

  function getLikedPosts() {
    var likedPosts = localStorage.getItem("liked_posts");
    return likedPosts ? JSON.parse(likedPosts) : [];
  }

  function saveLikedPosts(likedPosts) {
    localStorage.setItem("liked_posts", JSON.stringify(likedPosts));
  }

  var likedPosts = getLikedPosts();

  if (likedPosts.includes(postId)) {
    $likeButton.addClass("pressed").prop("disabled", false);
  }

  $likeButton.on("click", function () {
    var $this = $(this);

    var action = likedPosts.includes(postId) ? "remove" : "add";

    $.ajax({
      url: ANAGLOBALS.ajaxurl,
      type: "post",
      data: {
        action: "update_like_status",
        post_id: postId,
        like_action: action,
        nonce: ANAGLOBALS.likeNonce,
      },
      success: function (response) {
        $("#like-count").text(response.data);

        if (action === "add") {
          likedPosts.push(postId);
          $this.addClass("pressed");
        } else {
          likedPosts = likedPosts.filter(function (id) {
            return id !== postId;
          });
          $this.removeClass("pressed");
        }

        saveLikedPosts(likedPosts);
      },
      complete: function () {
        $this.prop("disabled", false);
      },
    });
  });
});
