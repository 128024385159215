jQuery(document).ready(function ($) {
  if ($(".hero-slider").length) {
    $(".hero-slider")
      .not(".slick-initialized")
      .slick({
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        touchMove: false,
        draggable: false,
        swipe: false,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: false,
        arrows: true,
        prevArrow: $(".hero-slider-arrows .prev-slide"),
        nextArrow: $(".hero-slider-arrows .next-slide"),
        responsive: [
          {
            breakpoint: 701,
            settings: {
              touchMove: true,
              draggable: true,
              swipe: true,
            },
          },
        ],
      });
  }
});

jQuery(document).ready(function ($) {
  var $sliderWrap = $(".blog-list-slider__wrap");
  var $prevArrow = $(".blog-list-slider .prev-slide");
  var $nextArrow = $(".blog-list-slider .next-slide");

  if ($sliderWrap.length) {
    $sliderWrap.not(".slick-initialized").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      speed: 0,
      touchMove: false,
      draggable: false,
      swipe: false,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1001,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // slider fade animation
    function animateSlider(direction) {
      $sliderWrap.animate({ opacity: 0 }, 200, function () {
        $sliderWrap.slick(direction);
        $sliderWrap.animate({ opacity: 1 }, 500);
      });
    }

    $prevArrow.on("click", function () {
      animateSlider("slickPrev");
    });

    $nextArrow.on("click", function () {
      animateSlider("slickNext");
    });

    // set same height to all slides
    function setEqualHeight() {
      var maxHeight = 0;

      $(".blog-list-slider__wrap .text-wrap").each(function () {
        var thisHeight = $(this).outerHeight();
        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
      });

      $(".blog-list-slider__wrap .text-wrap").each(function () {
        $(this).height(maxHeight);
      });
    }

    setEqualHeight();

    $(window).on("resize", function () {
      setEqualHeight();
    });
  }
});

jQuery(document).ready(function ($) {
  var sliderWrap = $(".ea-slider__wrap");
  var prevArrow = $(".ea-slider .ea-slider__prev-slide");
  var nextArrow = $(".ea-slider .ea-slider__next-slide");

  if (sliderWrap.length) {
    sliderWrap.not(".slick-initialized").slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: true,
      speed: 1000,
      touchMove: false,
      draggable: false,
      swipe: false,
      dots: false,
      arrows: true,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      responsive: [
        {
          breakpoint: 1001,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // set same height to all slides
    function setEqualHeight() {
      var maxHeight = 0;

      $(".ea-slider__wrap .text-wrap").each(function () {
        var thisHeight = $(this).outerHeight();
        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
      });

      $(".ea-slider__wrap .text-wrap").each(function () {
        $(this).height(maxHeight);
      });
    }

    setEqualHeight();

    $(window).on("resize", function () {
      setEqualHeight();
    });
  }
});

jQuery(document).ready(function ($) {
  var slider = $(".gallery");

  if (slider.length) {
    slider.not(".slick-initialized").slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      speed: 1000,
      centerMode: true,
      variableWidth: true,
      touchMove: false,
      draggable: false,
      swipe: false,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            variableWidth: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
});
